<template>
  <v-card>
    <v-system-bar color="primary darken-3"></v-system-bar>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <a href="/" class="logo-link">
          <img
            class="fade-in"
            src="/img/clutcharena.svg"
            alt="CS:GO Logo"
            style="height: 40px; padding-top: 5px;"
          />
        </a>
        <!-- {{ $t("Navbar.title") }} -->
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        rounded
        color="darken-2"
        :href="apiUrl + '/auth/steam'"
        v-if="user.id == null"
        class="login-btn"
      >
        <v-icon left>
          mdi-steam
        </v-icon>
        <span class="login-text">Login via Steam</span>
      </v-btn>

      <v-menu
        v-if="user.id !== null"
        bottom
        offset-y
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            rounded
            fab
            small
            color="grey darken-2"
          >
            <img :src="user.small_image" style="border-radius: 15px;" />
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item :to="'/user/' + user.id">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Navbar.Profile") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="apiUrl + '/logout'">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Navbar.Logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <v-tooltip v-if="user.id === null" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" rounded fab small color="grey darken-2" @click="loginDialog = true">
            <v-icon>mdi-login-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Login.title") }}</span>
      </v-tooltip>
      <v-btn :to="'/user/' + user.id" v-if="user.id !== null" fab small>
        <img :src="user.small_image" style="border-radius: 15px;" />
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary fixed app>
      <v-list>
        <v-list-item v-if="user.id != null">
          <v-list-item-avatar>
            <v-img :src="user.small_image"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item dense v-if="user.id != null">
          <v-list-item-content>
            <v-list-item-title class="text-h9">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-list-item index="Home" :to="'/'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.Home") }}</v-list-item-title>
          </v-list-item>

          <v-list-item index="SkinChanger" :to="'/skins'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-knife-military</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Skin Changer</v-list-item-title>
          </v-list-item>

          <v-list-item index="BuyVip" :to="'/plans'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-crown</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Premium</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/ranking'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-clipboard-flow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t("Navbar.PlayerLeader") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item index="Matches" :to="'/matches'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-gamepad-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllMatches") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="user.id != null"
            index="mymatches"
            :to="'/mymatches'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-gamepad-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyMatches") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="(user.id != null) && (user.admin === 1 || user.super_admin === 1)"
            index="match_create"
            :to="'/match/create'"
          >
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-creation</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t("Navbar.CreateMatch")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="user.id != null" :to="'/myteams'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-spider-web</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyTeams") }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/teams'" exact>
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-bulletin-board</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllTeams") }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="user.id != null" :to="'/teams/create'" exact>
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-bullseye-arrow</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.CreateTeam") }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="(user.id != null) && (user.admin === 1 || user.super_admin === 1)" :to="'/myservers'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-server</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MyServers") }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="(user.id != null) && (user.admin === 1 || user.super_admin === 1)" @click="newDialog = true">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-server-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AddServer") }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/seasons'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.AllSeasons") }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="(user.id != null) && (user.admin === 1 || user.super_admin === 1)" :to="'/myseasons'">
            <v-list-item-icon>
              <v-icon dense color="gray darken-2">mdi-seal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("Navbar.MySeasons") }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <ServerDialog
      v-model="newDialog"
      :serverInfo="{}"
      :title="$t('MyServers.New')"
    />
    <LoginDialog v-model="loginDialog" :title="$t('Login.title')" />
  </v-card>
</template>
<script>
import ServerDialog from "./ServerDialog";
import LoginDialog from "./LoginDialog";
import { mapState } from "vuex";

export default {
  name: "Navbar",
  components: {
    ServerDialog,
    LoginDialog
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      drawer: false,
      group: null,
      newDialog: false,
      loginDialog: false,
      apiUrl: process.env?.VUE_APP_G5V_API_URL || "/api"
    };
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>

<style scoped>
.v-application .primary--text {
  color: #a6a6a6 !important;
  caret-color: #424242 !important;
}
.login-btn {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1000;
  background-color: #333; /* Preto claro */
  color: white; /* Cor da fonte */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.login-text {
  text-transform: none; /* Mantém Maiúsculo e Minúsculo */
}

.logo-link {
  display: inline-block; /* Faz o link ocupar apenas o espaço necessário */
  cursor: pointer; /* Muda o cursor para a mão ao passar sobre o link */
  text-decoration: none; /* Remove o sublinhado do link */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-out;
}
</style>
