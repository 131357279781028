import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC-uHFVz2b9Zg7J5gtk2vhix7U1yCirTv4",
  authDomain: "socriadiscordbot.firebaseapp.com",
  projectId: "socriadiscordbot",
  storageBucket: "socriadiscordbot.appspot.com",
  messagingSenderId: "874885818304",
  appId: "1:874885818304:web:5cfc42aae2dc02968bca25",
  measurementId: "G-YRXYEHS58L"
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);

export default {
  install(Vue) {
    Vue.prototype.$firestore = db;
  }
};
