<template>
  <div class="title-bar">
    <div class="title-overlay">
      <div class="title-content">
        <!-- Adiciona a chave para forçar a recriação do elemento -->
        <h1 :key="animationKey" class="slide-in">{{ subTitleNav }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["subTitleNav"]),
    animationKey() {
      // Gera uma chave única que muda quando subTitleNav muda
      return this.subTitleNav + Date.now();
    }
  }
};
</script>

<style>
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
}

.title-bar {
  background-image: url("../../public/img/backgrounds/bg_card.png");
  background-size: cover;
  background-position: center;
  padding-top: 10;
  height: 180px; /* Ajuste a altura conforme necessário */
  border-radius: 0;
  text-align: center;
  margin: 0; /* Remova as margens */
  position: relative; /* Para o overlay */
}
.title-overlay {
  background-color: rgba(0, 0, 0, 0.548);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
}

.title-content {
  display: flex;
  align-items: flex-end; /* Alinha verticalmente no fundo */
  justify-content: flex-start; /* Alinha horizontalmente à esquerda */
  height: 100%;
  padding: 0 20px 20px 70px; /* Ajusta a posição do texto */
}

.title-content h1 {
  color: rgb(185, 183, 183);
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
</style>
